import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TableComponent from "../Tables/TableComponent";
import { envanterColumns } from "../../helper/columns";
import { Typography, Modal } from "@mui/material";

const EnvanterTabs = ({ envanterData }) => {
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fatura detayını almak ve modalda göstermek için bir fonksiyon
  const handleInvoiceClick = async (vkn, faturaNo) => {
    try {
      const response = await fetch(
        `/api/mukellef_yonetimi/invoice_detail_view/${vkn}/${faturaNo}/`
      );
      const invoiceHtml = await response.text();
      setCurrentInvoice(invoiceHtml);
      setOpen(true);
    } catch (error) {
      console.error("Fatura detayları alınamadı:", error);
    }
  };

  const handleClose = () => {
    setCurrentInvoice("");
    setOpen(false);
  };

  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Fatura Kayıtlarının Gösterimi
      </Typography>
      <Box sx={{ width: "100%", typography: "body1", mt: 1 }}>
        <TabContext
          value={value}
          sx={{
            mt: 1,
            backgroundColor: "tableColor.main",
            color: "secondary.second",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "tableColor.main",
              color: "secondary.second",
              "& .MuiTabs-indicator": {
                backgroundColor: "secondary.second",
              },
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                color: "secondary.second",
                "& .Mui-selected": {
                  backgroundColor: "#0092CA",
                  color: "secondary.second !important",
                  borderRadius: "0.5rem",
                  m: 1,
                },
                "& .MuiTab-textColorPrimary": {
                  color: "secondary.second",
                },
              }}
            >
              <Tab value="1" label="Alışlar Temel" />
              <Tab value="2" label="Alışlar İstisna" />
              <Tab value="3" label="Satışlar Temel" />
              <Tab value="4" label="Satışlar İstisna" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ color: "secondary.second" }}>
            <TableComponent
              data={envanterData["alislarTemelData"]}
              tableColumns={envanterColumns}
              onInvoiceClick={handleInvoiceClick} // Yeni prop
            />
          </TabPanel>
          <TabPanel value="2" sx={{ color: "secondary.second" }}>
            <TableComponent
              data={envanterData["alislarIstisnaData"]}
              tableColumns={envanterColumns}
              onInvoiceClick={handleInvoiceClick}
            />
          </TabPanel>
          <TabPanel value="3" sx={{ color: "secondary.second" }}>
            <TableComponent
              data={envanterData["satislarTemelData"]}
              tableColumns={envanterColumns}
              onInvoiceClick={handleInvoiceClick}
            />
          </TabPanel>
          <TabPanel value="4" sx={{ color: "secondary.second" }}>
            <TableComponent
              data={envanterData["satislarIstisnaData"]}
              tableColumns={envanterColumns}
              onInvoiceClick={handleInvoiceClick}
            />
          </TabPanel>
        </TabContext>
      </Box>

      {/* Modal for displaying invoice */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            backgroundColor: "white",
            p: 4,
            borderRadius: "8px",
            boxShadow: 24,
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: currentInvoice }} />
        </Box>
      </Modal>
    </>
  );
};

export default EnvanterTabs;
