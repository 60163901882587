import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, TablePagination } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  useDeleteCustomerMutation,
  useGetCustomersQuery,
} from "../../services/customers";

export const btnStyle = {
  cursor: "pointer",
  color: "secondary.second",
  "&:hover": {
    color: "red",
  },
};

function Row(props) {
  const { row, openRowId, setOpenRowId, handleOpen, setInitialState, deleteCustomer } = props;
  const isOpen = openRowId === row.id; // Hangi satırın açık olduğunu kontrol eder

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenRowId(isOpen ? null : row.id)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ad_soyad}
        </TableCell>
        <TableCell align="center">{row.tckn}</TableCell>
        <TableCell align="center">{row.ticSicilNo}</TableCell>
        <TableCell align="center">{row.vdKodu}</TableCell>
        <TableCell align="center">{row.telefon}</TableCell>
        <TableCell align="center">{row.email}</TableCell>
        <TableCell align="center">
          <EditIcon
            sx={btnStyle}
            onClick={() => {
              handleOpen();
              setInitialState(row);
            }}
          />
          <DeleteOutlineIcon
            sx={btnStyle}
            onClick={() => deleteCustomer(row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={isOpen}
            timeout="auto"
            sx={{
              display: "flex",
              backgroundColor: "subTableColor.main",
              borderRadius: "4px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              marginTop: 1,
              border: "2px solid black",
            }}
            unmountOnExit
          >
            <Box sx={{ margin: 1, width: "100%" }}>
              <Typography variant="h6" gutterBottom component="div">
                E-Arşiv Portal Bilgileri
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ textDecoration: "underline", fontSize: "1rem" }}
                    >
                      E-Arşiv Portal Kimlik No
                    </TableCell>
                    <TableCell
                      sx={{ textDecoration: "underline", fontSize: "1rem" }}
                    >
                      E-Arşiv Portal Şifre
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.e_arsiv_portal_kimlik_no}
                    </TableCell>
                    <TableCell>{row.e_arsiv_portal_password}</TableCell>
                    {row.e_arsiv_portal_kimlik_no && (
                     <TableCell>
                     <Box
                       component="div"
                       sx={{ display: "inline" }}
                     >
                       <input
                         type="hidden"
                         id="userid"
                         name="userid"
                         value={row.e_arsiv_portal_kimlik_no} // Kullanıcının kimlik numarası
                       />
                       <input
                         type="hidden"
                         id="password"
                         name="password"
                         value={row.e_arsiv_portal_password} // Kullanıcının şifresi
                       />
                       <Button
                         variant="contained"
                         color="btnActiveColor" // Önceki renkte tutmak için
                         onClick={() => {
                           window.open("https://dijital.gib.gov.tr/portal/login", "_blank");
                         }}
                       >
                         E-Arşİv Portala Git
                       </Button>
                     </Box>
                   </TableCell>
                   
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box sx={{ margin: 1, width: "100%" }}>
              <Typography variant="h6" gutterBottom component="div">
                Hissedarlar
              </Typography>
              <Table aria-label="hissedar">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ textDecoration: "underline", fontSize: "1rem" }}
                    >
                      Adı Soyadı
                    </TableCell>
                    <TableCell
                      sx={{ textDecoration: "underline", fontSize: "1rem" }}
                    >
                      Hisse Oranı
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.hissedarlar.map((hissedar, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {hissedar.adi_soyadi}
                      </TableCell>
                      <TableCell>{hissedar.hisse_orani} %</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CustomerTable({ handleOpen, setInitialState }) {
  const { data: customers } = useGetCustomersQuery();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [openRowId, setOpenRowId] = React.useState(null); // Global state for open row
  const [deleteCustomer] = useDeleteCustomerMutation(); // deleteCustomer'ı alın

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        mt: 1,
        backgroundColor: "tableColor.main",
        color: "secondary.second",
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Ad-SoyAd / Unvan</TableCell>
            <TableCell align="center">TCKN</TableCell>
            <TableCell align="center">Ticaret Sicil Numarası</TableCell>
            <TableCell align="center">Vergi Dairesi Kodu</TableCell>
            <TableCell align="center">Telefon</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? customers?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : customers
          )?.map((row, i) => (
            <Row
              key={i}
              row={row}
              openRowId={openRowId}
              setOpenRowId={setOpenRowId}
              handleOpen={handleOpen}
              setInitialState={setInitialState}
              deleteCustomer={deleteCustomer} // deleteCustomer prop olarak gönderildi
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={customers?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Satır sayısı:"
      />
    </TableContainer>
  );
}
