import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const CustomersSalesPurchasesTable = ({ selectedMusteri }) => {
  const [alisSatısMusteriKayitlari, setAlisSatısMusteriKayitlari] = useState([]);
  const [errorAlisSatıs, setErrorAlisSatıs] = useState(false);
  const [loadingAlisSatıs, setLoadingAlisSatıs] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [formValues, setFormValues] = useState({});
  const axiosWithToken = useAxios();

  useEffect(() => {
    if (selectedMusteri) {
      const fetchStokDurumu = async () => {
        const url = `api/indirilecek_KDV/get_buyer_seller_list/${selectedMusteri?.value}/`;
        setErrorAlisSatıs(false);
        setLoadingAlisSatıs(true);
        setAlisSatısMusteriKayitlari([]);
        try {
          const response = await axiosWithToken(url);
          if (response.status === 200) {
            const data = response.data;
            setAlisSatısMusteriKayitlari(data.data);
          }
        } catch (error) {
          setErrorAlisSatıs(error.response?.data?.error);
          setAlisSatısMusteriKayitlari([]);
        } finally {
          setLoadingAlisSatıs(false);
        }
      };

      fetchStokDurumu();
    }
  }, [selectedMusteri]);

  const handleEditClick = (item) => {
    setEditingItem(item);
    setFormValues(item); // Mevcut değerleri forma aktar
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingItem(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const url = `/api/mukellef_yonetimi/update_defter_info/`; // Doğru endpoint
      const response = await axiosWithToken.post(url, {
        cari_id: editingItem.cari_id, // ID gönderiliyor
        ...formValues, // Tüm form verileri gönderiliyor
      });

      if (response.status === 200) {
        // Güncellemeden sonra tabloyu yenile
        setAlisSatısMusteriKayitlari((prev) =>
          prev.map((item) =>
            item.cari_id === editingItem.cari_id ? { ...item, ...formValues } : item
          )
        );
        handleCloseModal(); // Modalı kapat
      }
    } catch (error) {
      console.error("Detayları güncelleme hatası:", error.response || error);
    }
  };

  const Row = ({ item }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ color: "#007BFF" }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell align="left" sx={{ fontWeight: "bold", color: "#212529" }}>
            {item["Ad Soyad/Ünvan"]}
            <br />
            <span style={{ color: "#6C757D" }}>{item["VKN/TCKN"]}</span>
          </TableCell>
          <TableCell align="center" sx={{ color: "#212529" }}>
            {item["Adres"]}
          </TableCell>
          <TableCell align="center" sx={{ color: "#212529" }}>
            {item["İrtibat No"] || "-"}
          </TableCell>
          <TableCell align="center" sx={{ color: "#212529" }}>
            {item["e-mail Adresi"] || "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                margin={2}
                sx={{
                  border: "1px solid #DEE2E6",
                  borderRadius: "4px",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ color: "#212529", fontWeight: "bold" }}
                >
                  Detaylar
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Dönemi
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Dönemi"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Defterin Cinsi
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Defterin Cinsi"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Onay Makamı
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Onay Makamı"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Onay Tarihi
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Onay Tarihi"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Cari Bakiye
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Cari Bakiye"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Toplam Borç
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Toplam Borç"] || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ color: "#495057" }}>
                      Toplam Alacak
                    </Typography>
                    <Typography sx={{ color: "#212529" }}>
                      {item["Toplam Alacak"] || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => handleEditClick(item)}
                  sx={{ mt: 2 }}
                >
                  Düzenle
                </Button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Typography
        component="h3"
        variant="h5"
        align="center"
        color="#212529"
        mt={2}
      >
        Alış - Satış Müşteri Kayıtları
      </Typography>
      {loadingAlisSatıs && <LoadingData />}
      {errorAlisSatıs && <ErrorData error={errorAlisSatıs} />}
      {alisSatısMusteriKayitlari.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "100%",
            maxHeight: "450px",
            mt: 1,
            backgroundColor: "tableColor.main",
            color: "secondary.second",
          }}
        >
          <Table aria-label="customer table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ color: "#212529", fontWeight: "bold" }}>
                  Ad Soyad/Ünvan
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#212529", fontWeight: "bold" }}
                >
                  Adres
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#212529", fontWeight: "bold" }}
                >
                  İrtibat No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#212529", fontWeight: "bold" }}
                >
                  e-mail Adresi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ maxWidth: "100%" }}>
              {alisSatısMusteriKayitlari.map((item, index) => (
                <Row key={index} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Detayları Düzenle</DialogTitle>
        <DialogContent>
          <TextField
            label="Dönemi"
            name="Dönemi"
            value={formValues.Dönemi || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Defterin Cinsi"
            name="Defterin Cinsi"
            value={formValues["Defterin Cinsi"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Onay Makamı"
            name="Onay Makamı"
            value={formValues["Onay Makamı"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Onay Tarihi"
            name="Onay Tarihi"
            value={formValues["Onay Tarihi"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cari Bakiye"
            name="Cari Bakiye"
            value={formValues["Cari Bakiye"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Toplam Borç"
            name="Toplam Borç"
            value={formValues["Toplam Borç"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Toplam Alacak"
            name="Toplam Alacak"
            value={formValues["Toplam Alacak"] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="btnActiveColor" variant="contained">İptal</Button>
          <Button onClick={handleSave} color="btnActiveColor" variant="contained">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomersSalesPurchasesTable;
